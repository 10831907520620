import $ from 'jquery'
import Modal from 'bootstrap/js/src/modal'
export default class flowerMatchModal {
  constructor(element) {
    this.$element = $(element)
    const modalId = this.$element.data('modalId')
    this.$modal = $(`#${modalId}`)
    this.modal = new Modal(this.$modal.get(0), {})

    this.$modalSelect = this.$modal.find('select')
    this.$modalButtons = this.$modal.find('.modal-footer [type=button]')
    this.$modalClose = this.$modal.find('.modal-header .btn-close')
    this.$modalBoxImportString = this.$modal.find('.importString')
    this.$importString = this.$element.find('.importString')
    this.lookupId = this.$element.data('lookupId')
    this.choices = this.$modalSelect.data('choicesInstance');
    this.choices.config.searchResultLimit = 10
    this.$editButton = this.$element.find('button.editButton')
    this.$input = this.$element.find('input[type=hidden]')
    this.$selectedText = this.$element.find('.selectedText')

    this.$editButton.on('click.flowerMatchModal', this.handleEditButtonClick.bind(this))
  }

  handleModalButtonClick (event) {
    const $button = $(event.target) // Button that triggered the modal
    const action = $button.data('action') // Extract info from data-* attributes
    if (action === 'save') {
      this.save();
    } else {
        this.modal.hide()
    }
  }

  handleEditButtonClick(event) {
    // console.log('handleEditButtonClick')
    this.openModal()
  }


  save() {
    const valueObj = this.choices.getValue();
    if (valueObj.value) {
      this.$selectedText.text(valueObj.label)
      this.$input.val(valueObj.value)
    } else {
      this.$selectedText.text('')
      this.$input.val('')
    }
    this.saveAjax()
  }


  saveAjax(){

      this.$selection = this.$modal.find('div.choices__item.choices__item--selectable')
      this.lookupId = this.$modal.find('input[name=lookupId]').val()

      let baseUrl = window.location.protocol + "//" + window.location.host + window.location.pathname+"Ajax";

      let formData = {
          'lookupId': this.lookupId,
          'flowerId': this.$selection.data('value')
      };

      let that = this

      $.ajax({
          url: baseUrl,
          type: 'POST',
          data: formData,
          success: function(response) {
              // Erfolgreiche Antwort des Servers
              console.log('Erfolg:', response);
              that.modal.hide()
          },
          error: function(xhr, status, error) {
              // Fehlerbehandlung
              console.error('Fehler:', xhr.responseJSON);
              that.openModal()
              alert('Fehler:' + xhr.responseJSON.error)
          }
      });
  }

  handleModalButtonCloseClick() {
    this.modal.hide()
  }

  handleModalClose() {
    this.$modalButtons.off('click.flowerMatchModal')
    this.$modal.off('hidden.bs.modal')
    this.$modalClose.off('click.flowerMatchModal')
  }


  openModal() {
    // console.log('openModal')

    this.$modalButtons.one('click.flowerMatchModal', this.handleModalButtonClick.bind(this))
    this.$modal.one('hidden.bs.modal', this.handleModalClose.bind(this))
    this.$modalClose.one('click.flowerMatchModal', this.handleModalButtonCloseClick.bind(this))
    this.$modalBoxImportString.text(this.$importString.text())
    this.$modal.find('input[name=lookupId]').val(this.lookupId)

    // console.log(this.$input.val())

    if (this.$input.val()) {
      this.choices.setChoiceByValue(this.$input.val())
    }

    const presetSearch = this.$importString.text().substr(0, 4)
    this.choices._searchChoices(presetSearch)
    const $searchField = $(this.choices.dropdown.element).find('input[name=search_terms]')
    $searchField.val(presetSearch)
    $searchField.focus()
    setTimeout(() => {
      this.cursorToEnd($searchField)
    }, 500)
    this.choices.showDropdown()
    this.modal.show()

  }

  cursorToEnd($searchField) {
    const searchField = $searchField.get(0)
    let len = $searchField.val().length;

    // Mostly for Web Browsers
    if (searchField.setSelectionRange) {
      searchField.focus();
      searchField.setSelectionRange(len, len);
    } else if (searchField.createTextRange) {
      let t = searchField.createTextRange();
      t.collapse(true);
      t.moveEnd('character', len);
      t.moveStart('character', len);
      t.select();
    }
  }
}
