import $ from 'jquery'
import WorkflowNavigation from "./workflowNavigation";

export default class workflowNavigationOnSelect extends WorkflowNavigation {
  constructor (element) {
    super(element);
    this.$workflowActionField = this.$form.find('input[name=workflowNavigation]')
    if (this.$workflowActionField.length < 1) {
      console.error('workflowNavigation field not found!')
    }
  }

  initiateHandler() {
    this.$element.on('change.workflowNavigationOnSelect', this.handleChange.bind(this))
  }

  handleChange (event) {
    event.preventDefault()

    // find field in workflowform
    const fieldName = this.$element.attr('name');
    const $workflowFormField = this.$form.find(`[name=${fieldName}]`)
    if ($workflowFormField.length > 0) {
      if ($workflowFormField !== this.$element) {
        $workflowFormField.val(this.$element.val())
      }
    }

    this.$workflowActionField.val(this.workflowAction)
    this.$form.trigger('submit')
  }
  destroy() {
    this.$element.off('change.workflowNavigationOnSelect')
  }

}
